@import "../../include";

.container {
  background-color: rgba($color: $colorGrey, $alpha: 0.2);
  width: 100%;
  padding: 90px 0 130px;
  @media #{$phone} {
    padding: 90px 0 60px;
  }
  @media #{$mobile-phone} {
    padding: 50px 0 40px;
  }
}
.advantageContainer {
  @extend %content-container;
}

.wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 70px;
  @media #{$phone} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 60px;
  }
}

.advantage {
  position: relative;
  display: flex;
  align-items: center;
  padding: 60px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  }

  @media #{$mobile-phone} {
    padding: 30px;
  }
}

.advantageImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  @media #{$mobile-phone} {
    width: 100%;
    height: auto;
  }
}
.advantageIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  & > svg {
    height: 6.2em;
  }
  @media #{$mobile-phone} {
    width: 75px;
    & > svg {
      height: 2.5em;
    }
  }

}

.advantageTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 90px;
  padding-left: 20px;
  max-width: 263px;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  border-left: 3px solid $colorGrey;
  @media #{$mobile-phone} {
    font-size: 24px;
    line-height: 24px;
    height: 45px;
  }
}
