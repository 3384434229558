.designElementsParentContainer {
  position: relative;
}

.container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateZ(0);

  overflow: hidden;

  width: 100%;
  height: 100%;
}