@import '../include';

.container {
  position: relative;
  background-color: $colorDark;
  width: 100%;
  height: 18.75em;
  padding: 90px 0 54px;
  overflow: hidden;
  @media #{$phone} {
    height: auto;
    padding: 70px 0 84px;
  }
  @media #{$mobile-phone} {
    height: 35.38em;
    padding: 62px 0 81px;
  }
}

.footerContainer {
  @extend %content-container;
  display: grid;
  grid-template-columns: 460px 690px 170px;
  grid-template-rows: 90px 1fr;
  color: $colorWhite;
  @media #{$phone} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 82px 67px 1fr;
  }
  @media #{$mobile-phone} {
    grid-template-columns: 1fr;
    grid-template-rows: 90px 169px 143px 1fr;
  }
}

.footerLogoWrap {
  margin-bottom: 42px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  & svg {
    width: 13.69em;
    height: auto;
  }
}

.footerSocialContainer {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  z-index: 1;
  @media #{$mobile-phone} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    padding-left: 26px;
  }
}

.footerSocialsWrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 13px;
}

.footerSocialIcon {
  &_telegram {
    width: 30px;
  }

  &_viber {
    width: 32px;
  }

  &_whatsapp {
    width: 38px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.copyright {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
}

.footerContactContainer {
  width: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 440px;
  @media #{$phone} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    padding-right: 0;
    padding-top: 69px;
  }
  @media #{$mobile-phone} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    padding-right: 0;
    padding-top: 0;
    padding-left: 26px;
  }
}

.footerContactLink {
  text-decoration: none;
  z-index: 1;
}

.footerContactLink:nth-child(3) {
  margin-bottom: 15px;
}

.footerContactText {
  font-size: 20px;
  font-family: JetBrains Mono, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: $colorWhite;
  white-space: nowrap;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: $colorAccent;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.footerContactsAddress {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  white-space: pre-line;
}

.logoXFooter {
  position: absolute;
  top: -90px;
  right: 37px;
  height: 18.75em;
  width: auto;
  @media #{$phone} {
    top: -7.3em;
    right: -26em;
    height: 27.06em;
  }
  @media #{$mobile-phone} {
    top: -4em;
    right: -8em;
    height: 18.75em;
  }
}
.logoJFooter {
  position: absolute;
  top: -90px;
  left: 0;
  height: 17.56em;
  width: auto;
  @media #{$phone} {
    top: -7.3em;
    left: -14em;
    height: 25.375em;
  }
  @media #{$mobile-phone} {
    top: -4em;
    left: -13.3em;
    height: 17.56em;
  }
}

.footerMenuLink {
  font-size: 18px;
  font-family: JetBrains Mono, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  text-decoration: none;
  color: $colorWhite;
  &:hover {
    color: $colorAccent;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.footerMenuContainer {
  display: flex;
  flex-direction: column;
  @media #{$phone} {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    padding-right: 3.3em;
    margin-left: auto;
    z-index: 1;
  }
  @media #{$mobile-phone} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-right: 0;
    padding-left: 54px;
    margin-left: 0;
  }  
}
