@import "../include";

.headerBox {
  position: sticky;
  z-index: 999;
  top: 0;

  background: rgba(98, 115, 132, 0.25);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(18px);
 
  &[unwrapper-node-type] { // Prevent hide container on unwrap menu
    display: block !important;
  }

  &.mobileMenuOpened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
}

.headerContainer {
  @extend %content-container;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  max-width: 1920px;
}

.leftContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  max-width: 300px;
}

.logo {
  width: 60px;

  svg {
    width: 100%;
    height: auto;
  }
}

.menuContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: left;

  & > div + div {
    margin-left: 45px;
  }

  @media #{$phone} {
    display: none;
  }
}

.menuLink {
  position: relative;

  display: flex;
  flex-shrink: 0; // Prevent new lines in menu
  align-items: center;
  justify-content: center;

  min-height: 48px;
  margin-bottom: 4px;
  padding: 5px;

  font-family: JetBrains Mono, sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
  color: $colorDark;
  text-decoration: none;

  transition: 125ms linear color;

  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(199.35deg, #247CFF 0%, #00E1FF 100%);
    transition: width 0.4s, left 0.4s;
  }

  &.menuLinkActive {
    color: $colorAccent;
  }

  &:hover {
    &::after {
      width: 100%;
      left: 0;
    }
  }

}

.rightContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.contactBtn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 218px;
  min-height: 68px;
  padding: 4px;

  color: #4690FF;
  text-decoration: none;

  background: transparent;
  border: 2px solid currentcolor;
  border-radius: 14px;
  box-shadow: 0 0 5px -2px transparent;

  transition: 125ms linear box-shadow;

  &:hover {
    box-shadow: 0 0 8px rgb(70 144 255 / 97%);
  }

  &:active {
    box-shadow: 0 0 8px rgb(70 144 255 / 97%)
  }

  span {
    font-family: Commissioner, sans-serif;
    font-size: 22px;
    font-weight: 900;
    font-style: normal;
    line-height: 24px;
    text-align: center;
  }

  span + svg {
    margin-left: 9px;
  }

  @media #{$phone} {
    display: none;
  }
}

.burgerButton {
  cursor: pointer;

  display: none;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  background: transparent;
  border: none;

  svg {
    display: none;
    color: #000;
  }

  @media #{$phone} {
    display: flex;
  }

  @media #{$phone} {
    margin-right: -10px;
  }
}

.burgerIcon {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  width: 30px;
  height: 22px;


  i {
    transform-origin: center right;

    display: block;

    width: 100%;
    height: 3px;

    color: #000;

    background-color: currentcolor;
    border: 0 none;
    border-radius: 3px;

    transition: transform 150ms ease-out;
  }

  i:nth-child(2) {
    align-self: flex-end;
    width: 50%;
  }

  i:nth-child(1), i:nth-child(3) {
    transform-origin: right center;
    transform: translate(0, 0) rotate(0);
  }

  // stylelint-disable-next-line no-duplicate-selectors
  i:nth-child(2) {
    transform-origin: 20% center;
    transform: scaleX(1);
  }
}

.mobileMenuOpened {
  i:nth-child(1) {
    transform: translate(0, -1px) rotate(-45deg);
  }

  i:nth-child(2) {
    transform: scaleX(0);
  }

  i:nth-child(3) {
    transform: translate(0, 1px) rotate(45deg);
  }
}

.mobileMenu {
  display: none;
}

.mobileMenu[unwrapper-node-type] {
  display: block;
}
