@import '../../include';

.container {
  position: relative;
  background-color: rgba($color: $colorGrey, $alpha: 0.2);
  width: 100%;
  padding: 90px 0 130px;
  @media #{$phone} {
    padding: 90px 0 90px;
  }
  @media #{$mobile-phone} {
    padding: 50px 0 90px;
  }
}
.aboutUsContainer {
  @extend %content-container;
}

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  margin-bottom: 33px;

  @media #{$phone} {
    grid-template-columns: 1fr;
    gap: 26px;
    margin-bottom: 0;
  }
}
.aboutUsImageWrap {
  border-radius: 15px;
  overflow: hidden;
}
.contentWrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media #{$phone} {
    gap: 26px;
  }
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: $colorGrey;

  @media #{$mobile-phone} {
    font-size: 16px;
  }
}
.importantText {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: $colorGrey;
  @media #{$mobile-phone} {
    font-size: 16px;
  }
}

.logoWrap {
  display: flex;
  justify-content: left;

  & > svg {
    height: 3.55em;
    width: auto;
    @media #{$phone} {
      height: 1.7em;
    }
  }

  @media #{$phone} {
    position: absolute;
    width: 100%;
    justify-content: right;
    bottom: 30px;
    right: 30px;
  }
}
