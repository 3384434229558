@import '../../include';

.container {
  background-color: rgba($color: $colorGrey, $alpha: 0.2);
  width: 100%;
  padding: 90px 0 55px;
  @media #{$mobile-phone} {
    padding: 50px 0 30px;
  }
}

.reviewsList {
  @media #{$phone} {
    width: 100%;
    padding: 0 60px;
  }
  @media #{$mobile-phone} {
    padding: 0 30px;
  }
}

.reviewsList {
  display: flex;
  overflow: hidden;
}
.card {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 10px;
  padding: 10px 20px 55px 10px;
  max-width: 462px;
  border-radius: 15px;
  background-color: $colorGrey;
  margin: 5px 12px 50px;
  box-shadow: 3px 6px 12px 0px rgba(0, 0, 0, 0.2);

  @media #{$phone} {
    max-width: 100%;
  }
  @media #{$mobile-phone} {
    grid-template-columns: 1fr;
    margin: 5px 12px 20px;
  }
}

.cardImageWrap {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.cardContent {
  position: relative;
  padding-top: 7px;
}

.iconWrap {
  position: absolute;
  right: 0;
  & > svg {
    height: 1em;
    width: auto;
  }
  @media #{$mobile-phone} {
    top: -60px;
  }
}

.cardName {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: $colorWhite;
  @media #{$mobile-phone} {
    font-size: 18px;
  }
}
.cardName:nth-child(3) {
  margin-bottom: 10px;
}

.cardCompany {
  font-weight: 500;
  font-size: 18px;
  color: $colorWhite;
  margin-bottom: 15px;
  @media #{$mobile-phone} {
    font-size: 16px;
  }
}

.cardDescription {
  font-size: 14px;
  color: $colorWhite;
  margin-bottom: 10px;
  @media #{$mobile-phone} {
    font-size: 12px;
  }
}

.swiperContainer {
  :global(.swiper-pagination-bullets.swiper-pagination-horizontal) {
    display: flex;
    align-items: center;
    justify-content: center;

    :global(.swiper-pagination-bullet) {
      width: 60px;
      height: 4px;
      margin: 0 8px;
      border-radius: 2px;

      opacity: 1;
      background-color: $colorGrey;
    }

    :global(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
      background: linear-gradient(225deg, #247cff 0%, #00e1ff 100%);
    }
  }
  :global(.swiper-slide-active) {
    & .card {
      background-color: $colorWhite;
    }
    & .cardDescription,
    & .cardCompany {
      color: $colorGrey;
    }
    & .cardName {
      color: $colorDark;
    }
  }
  // }
}
