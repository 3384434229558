@import '../../include';
.container {
  position: relative;
  width: 100%;
  padding: 90px 0 130px;
  @media #{$phone} {
    padding: 90px 0 60px;
  }
  @media #{$mobile-phone} {
    padding: 50px 0 40px;
  }
}

.background {
  position: absolute;
  top: 90px;
  left: -30vw;
  width: auto;
  height: auto;
  text-align: center;
  overflow: hidden;
  z-index: -1;

  & > svg {
    height: 90em;
  }
}

.servicesContainer {
  @extend %content-container;
}

.contentWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  @media #{$phone} {
    grid-template-columns: 1fr;
  }
  @media #{$mobile-phone} {
    gap: 30px;
  }
}

.service {
  padding: 30px 30px 60px;
  width: 100%;
  border-radius: 12px;
  background: $colorWhite;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
}

.serviceTitleContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.serviceIcon {
  padding-right: 16px;
  border-right: 3px solid $colorGrey;
  & > svg {
    height: 3.75rem;
  }
}

.serviceTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  max-width: 193px;
  @media #{$mobile-phone} {
    font-size: 22px;
    line-height: 22px;
  }
}

.serviceContent {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: $colorGrey;
  margin-bottom: 30px;
  @media #{$mobile-phone} {
    font-size: 18px;
  }
}

.serviceContentList {
  list-style-type: disc;
  padding-left: 30px;
}

.serviceContentListItem {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: $colorGrey;
  @media #{$mobile-phone} {
    font-size: 16px;
    line-height: 18px;
  }
}
