@import '../../include';
.container {
  width: 100%;
  padding: 90px 0 55px;
  @media #{$mobile-phone} {
    padding: 30px 0 20px;
  }
}

.teamContainer {
  @extend %content-container;
}

.teamList {
  display: flex;
}
.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  padding: 50px 60px;
  border-radius: 15px;
  background-color: $colorWhite;
  margin: 5px 5px 55px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);

  @media #{$phone} {
    grid-template-columns: 1fr;
    padding: 30px;
    gap: 30px;
  }
  @media #{$mobile-phone} {
    margin: 5px 5px 40px;
  }
}

.logoWrap {
  margin-bottom: 90px;
  & > svg {
    height: 30px;
    width: auto;

    @media #{$mobile-phone} {
      height: 15px;
    }
  }
  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.cardName {
  font-family: JetBrains Mono, sans-serif;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 84px;
  @media #{$phone} {
    margin-bottom: 31px;
  }
}

.cardHr {
  width: 100%;
  height: 2px;
  border: none;
  background-image: linear-gradient(225deg, #247cff 0%, #00e1ff 100%);
  margin-bottom: 60px;
  @media #{$phone} {
    margin-bottom: 30px;
  }
}

.cardTitle {
  color: $colorGrey;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 48px;
}

.cardDescription {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  color: $colorGrey;
}

.cardImage {
  border-radius: 15px;
  overflow: hidden;
}

.swiperContainer {
  :global(.swiper-pagination-bullets.swiper-pagination-horizontal) {
    display: flex;
    align-items: center;
    justify-content: center;

    :global(.swiper-pagination-bullet) {
      width: 60px;
      height: 4px;
      margin: 0 8px;
      border-radius: 2px;

      opacity: 1;
      background-color: $colorGrey;
    }

    :global(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
      background: linear-gradient(225deg, #247cff 0%, #00e1ff 100%);
    }
  }
}
