.container {
  position: relative;

  .placeholder,
  .noScriptImage,
  .image {
    z-index: 0;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .placeholder {
    opacity: 1;
    transition: 75ms opacity 150ms ease-out;
  }

  .image {
    opacity: 0;
    transition: opacity 125ms ease-in;
  }

  &[data-loaded] .placeholder {
    opacity: 0;
  }

  &[data-loaded] .image {
    opacity: 1;
  }

  &.hasPlaceholder {
    .placeholder {
      position: relative;
    }

    .noScriptImage {
      position: absolute;
    }

    .image {
      position: absolute;
    }
  }

  &.noPlaceholder {
    .noScriptImage {
      position: relative;
    }

    .image {
      position: relative;
    }
  }
}
