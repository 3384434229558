@import "../../include";

//relative height of banner image that is positioned absolute - desktop (1932x825)
$bannerImgHeightDesktop: calc(100vw / 2.356);

//relative height of banner image that is positioned absolute - tablet (768x1024)
$bannerImgHeightTablet: calc(100vw / 0.762);

//relative height of banner image that is positioned absolute - mobile phone (375x768)
$bannerImgHeightMobile: calc(100vw / 0.49);

.container {
  position: relative;
  background-color: rgba($color: $colorGrey, $alpha: 0.2);
  height: $bannerImgHeightDesktop;

  @media #{$phone} {
    height: $bannerImgHeightTablet;
  }
  @media #{$mobile-phone} {
    height: $bannerImgHeightMobile;
  }
}

.bannerImageMainOuterWrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  z-index: -2;
}
.bannerImageMainInnerWrap {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.logoJ {
  position: absolute;
  top: 0;
  left: 0;
  height: 95%;
  width: auto;
  z-index: -1;
  @media #{$phone} {
    top: 1%;
    left: -55%;
    height: 70%;
  }
  @media #{$mobile-phone} {
    top: 0;
    left: -100%;
    height: 70%;
  }
}

.logoX {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: auto;
  z-index: -1;
  @media #{$phone} {
    right: -40%;
    height: 70%;
  }
  @media #{$mobile-phone} {
    right: -73%;
    height: 75%;
  }
}



.mainTextContainer {
  @extend %content-container;
  padding-top: calc($bannerImgHeightDesktop * 0.24);

  @media #{$phone} {
    padding-top: calc($bannerImgHeightTablet * 0.20);
  }
  @media #{$mobile-phone} {
    padding-top: calc($bannerImgHeightMobile * 0.17);
  }

}

svg.logo {
  width: 17vw;
  height: auto;
  margin-bottom: 45px;
  @media #{$phone} {
    width: 49vw;
    margin-bottom: 35px;
  }
  @media #{$mobile-phone} {
    width: 58vw;
    margin-bottom: 30px;
  }
}

.mainText {
  font-family: JetBrains Mono, sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  max-width: 400px;
  @media #{$phone} {
    width: 280px;
  }
  @media #{$mobile-phone} {
    font-size: 24px;
    line-height: 24px
  }
}

.mainTextContainer + .bannerImageContainer {
  @media #{$tablet-and-desktop} {
    margin-left: 32px;
  }

  @media #{$phone} {
    margin-top: 48px;
  }
}
