@import "../../include";

.container {
  background: rgba(98, 115, 132, 0.25);
  height: calc(100vh - 90px);
}

.content {
  display: flex;
  gap: 60px;
  height: 100%;
  padding: 30px 30px 30px 90px;
    @media #{$mobile-phone} {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 30px;
    }
}

.menuContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.menuLink {
  position: relative;
  font-family: JetBrains Mono, sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
  color: $colorDark;
  text-decoration: none;

  transition: 125ms linear color;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(199.35deg, #247cff 0%, #00e1ff 100%);
    transition: width 0.4s, left 0.4s;
  }
  &:hover {
    &::after {
      width: 100%;
      left: 0;
    }
  }
}

.menuContainer + .contactBtn {
  margin-top: 47px;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: 2px solid $colorGrey;
  border-radius: 12px;
  padding: 27px 20px 27px 27px;
  margin-top: auto;
}

.contactsDetailes {
  margin-bottom: 17px;
}

.menuContactLink {
  text-decoration: none;
}
.menuContactText {
  font-family: JetBrains Mono, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $colorDark;
  &:hover {
    color: $colorAccent;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.contactsAddress {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 30px;
}

.socials {
  display: flex;
  align-items: center;
  gap: 15px;
}

.socialIcon {
  &_telegram {
    width: 30px;
  }

  &_viber {
    width: 34px;
  }

  &_whatsapp {
    width: 40px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}
